import React from 'react';
// import './App.css';
import Router from './pages/Router';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <Navbar />
      <div className="h-10"></div>
      <Router />
      <Footer />
    </>
  );
}

export default App;
import { useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FiEdit } from 'react-icons/fi'
import { Editor } from '@tinymce/tinymce-react'
import axios from 'axios'
import { EDIT_NEWS } from '../../utils/endPoint'
import { getUserToken } from '../../utils/types'

const EditBerita = () => {
  const location = useLocation()
  const [news, setNews] = useState(location.state.news)
  const navigate = useNavigate()
  //   const [content, setContents] = useState('')
  const editorRef = useRef(null)
  const [isFormValid, setIsFormValid] = useState(true)
  const [error,setError] = useState('')
  const [loading, setLoading] = useState(false)
  const handleEditorChange = (contents) => {
    setNews({ ...news, content: contents })
  }
  const type = [
    { id: 1, value: 'AJAK KEGIATAN' },
    { id: 2, value: 'PENGUMUMAN' },
    { id: 3, value: 'KAJIAN' },
    { id: 4, value: 'AKTIVITAS' },
  ]

  const onSubmit = (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append('title', news.title)
    data.append('type', news.type)
    if (e.target.image.files[0]) {
      data.append('image', e.target.image.files[0])
    }
    data.append('content', news.content)

    const submit = async () => {
      try {
        setLoading(true)
        const res = await axios.patch(EDIT_NEWS + news.id + '/', data, {
          headers: {
            Authorization: `Token ${getUserToken()}`,
          },
        })
        // console.log(res)
        setTimeout(() => {
          setLoading(false)
          navigate('/')
        }, 3000)
      } catch (err) {
        // console.log(err)
        setLoading(false)
        setError(err.message)
        setTimeout(()=>{
            setError('')
        },3000)
      }
    }
    submit()
    // console.log(e.target.image.files[0].type.includes('image'))
  }

  //   useEffect(()=>{
  //     console.log(news)
  //   },[news])
  return (
    <section className="lg:px-96 lg:py-24 px-6 py-6">
      <div className="flex my-6 items-center md:gap-3 gap-1">
        <FiEdit className="text-bluesaifillah-normal text-[30px] md:text-[50px]" />
        <h1 className="text-[30px] md:text-[50px] font-bold text-bluesaifillah-normal">
          Edit Berita
        </h1>
      </div>
      <form onSubmit={onSubmit}>
        {/* Title */}
        <div className="md:flex md:items-center mb-6">
          <div className="w-40">
            <label
              className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4"
              htmlFor="inline-full-name"
            >
              Judul Berita
            </label>
          </div>
          <div className="w-full">
            <input
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
              id="inline-full-name"
              type="text"
              name="title"
              value={news.title}
              onChange={(e) => setNews({ ...news, title: e.target.value })}
            />
          </div>
        </div>
        {/* Type */}
        <div className="md:flex md:items-center mb-6">
          <div className="w-40">
            <label
              className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4"
              htmlFor="inline-full-name"
            >
              Type
            </label>
          </div>
          <div className="w-full">
            <select
              className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
              id="inline-full-name"
              name="type"
              value={news.type}
              onChange={(e) => setNews({ ...news, type: e.target.value })}
            >
              <option value="">-</option>
              {type.map((val) => {
                return <option key={val.id}>{val.value}</option>
              })}
            </select>
          </div>
        </div>
        <div className="md:flex md:items-center mb-6">
          <div className="w-40">
            <label
              className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4"
              htmlFor="inline-full-name"
            >
              Url Gambar
            </label>
          </div>
          <div className="w-full">
            <input
              //   className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-400"
              id="inline-full-name"
              type="file"
              name="image"
              //   onChange={handleChangeImg}
              // value={news.image}
            />
            <img src={news.image} alt="img url" className='mt-2 rounded-md'/>
          </div>
        </div>
        <div className="md:flex md:items-center mb-6">
          <div className="w-40">
            <label
              className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4"
              htmlFor="inline-full-name"
            >
              Content
            </label>
          </div>
          <div className="w-full">
            <Editor
              value={news.content}
              onInit={(evt, editor) => (editorRef.current = editor)}
              // apiKey="yb64t1vjyri2cansglhq8gaz1tai01e0dc0g3jlm9a4cjvdn"
              apiKey="koceoiwytlyiwg8ifoagfwdyi3wvjg5fk88o3gakacslqc8y"
              init={{
                skin: 'snow',
                icons: 'thin',
                // placeholder:`${t('dashboard_teacher.create_course.description_message')}`,

                height: 300,
                menubar: true,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen textcolor ',
                  'insertdatetime media table paste code help wordcount',
                  'image code',
                ],
                image_title: true,
                automatic_uploads: true,
                file_picker_types: 'image',
                textcolor_rows: '4',
                file_picker_callback(callback, value, meta) {
                  if (meta.filetype === 'image') {
                    const input = document.getElementById('my-file')
                    input.click()
                    input.onchange = function () {
                      const file = input.files[0]
                      const reader = new FileReader()
                      reader.onload = function (e) {
                        callback(e.target.result, {
                          alt: file.name,
                        })
                      }
                      reader.readAsDataURL(file)
                    }
                  }
                },
                content_style:
                  "@import url('http://fonts.gstatic.com/s/nunito/v7/1TiHc9yag0wq3lDO9cw0vpBw1xU1rKptJj_0jans920.woff2');",
                font_formats: 'Nunito',

                toolbar:
                  'undo redo | styleselect | fontselect | fontsizeselect| code | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
              }}
              onEditorChange={handleEditorChange}
              outputFormat="html"
            />
          </div>
        </div>

        <div className="flex justify-end gap-2">
          {!isFormValid && (
            <p className="text-red-500 mt-2">
              Harap isi semua input sebelum mengirimkan formulir.
            </p>
          )}
          {loading ? <p className="mt-2">Loading...</p> : null}
          {error ? <p className="mt-2 text-red-600">{error}</p> : null}
          <button className=" bg-bluesaifillah-normal p-2 md:w-24 w-20 rounded-md text-white text-[15px] md:text-[18px]">
            Kembali
          </button>
          <button
            type="submit"
            className=" bg-bluesaifillah-normal p-2 md:w-24 w-20 rounded-md text-white text-[15px] md:text-[18px]"
          >
            Edit
          </button>
        </div>
      </form>
    </section>
  )
}
export default EditBerita

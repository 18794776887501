import React, { useState } from "react";
import * as yup from "yup";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import FormInputField from "../../components/form/FormInputField";
import ErrorMessage from "../../components/ErrorMessage";
import ButtonPrimary from "../../components/ButtonPrimary";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 30,
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const InfaqRamadhan = () => {
  const [errorRegister, setErrorRegister] = useState();
  const [loading, setLoading] = useState(false);
  // const [tipe, setTipe] = useState("");
  const [payment, setPayment] = useState("");
  const [nom, setNom] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [modalIsOpen2, setIsOpen2] = React.useState(false);

  const openModal2 = () => {
    setIsOpen2(true);
  };

  const closeModal2 = () => {
    setIsOpen2(false);
  };

  const schema = yup
    .object({
      name: yup.string().required("Nama lengkap harus diisi"),
      phone: yup
        .number("nomor telepon harus berupa angka")
        .typeError("Nomor telepon harus berupa angka")
        .required("Nomor telepon harus diisi"),
      nominal: yup
        .number("Nominal  harus berupa angka")
        .typeError("Nominal harus berupa angka")
        .required("Nominal harus diisi"),
    })
    .required();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      phone: "",
      nominal: "",
    },
  });
  const onSubmit = (e) => {
    setNom(e.nominal);
    setName(e.name);
    setPhone(e.phone);

    if(!confirm){
      openModal();
    }
    if (confirm) {
      const billing = new FormData();
      console.log("ee", e);

      billing.append("billingId", 80); // hardcode billing for Ramadhan Infaq idBilling: 80
      billing.append("status", "Belum Lunas");
      billing.append("payment_method", "Xendit");
      billing.append("transaction_fee", 4000);
      billing.append("vat_fee", 440);
      billing.append("platform_fee", 1500);
      billing.append(
        "description",
        `Infaq Saifillah Ramadhan 2023 - ${e.name} - ${e.phone}`
      );
      billing.append("total_price", parseInt(e.nominal) + 440 + 4000 + 1500);
      setLoading(true);
      axios
        .post("https://digitalcity.id/api/publicorder2/", billing)
        .then((res) => {
          window.open(res.data.payment_url);
        })
        .catch((err) => {
          if (err.response.data?.message) {
            setErrorRegister(err.response.data.message);
            setTimeout(() => {
              setErrorRegister();
            }, 8000);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <div className="bg-gray-50">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div>
          <div className="flex items-center justify-end">
            <div onClick={closeModal} className="cursor-pointer">
              X
            </div>
          </div>
        </div>
        <div className="mb-6 p-6">
          <h2 className="font-bold text-xl text-center text-bluesaifillah-normal mb-6">
            KONFIRMASI
          </h2>
          <h2 className="font-bold text-center mb-3">
            Mohon mengecek kembali nominal infaq yang ada dibawah ini:
          </h2>
          <div>
            <div className="flex justify-between">
              <div>Nominal Infaq</div>
              <div>
                {" "}
                <NumericFormat
                  value={nom}
                  displayType={"text"}
                  thousandSeparator=","
                  prefix={"Rp. "}
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div>Biaya Transaksi</div>
              <div>
                {" "}
                <NumericFormat
                  value={5940}
                  displayType={"text"}
                  thousandSeparator=","
                  prefix={"Rp. "}
                />
              </div>
            </div>
            <hr className="mt-3 mb-3"></hr>
            <div className="flex justify-between">
              <div className=" font-bold">Total</div>
              <div>
                {" "}
                <NumericFormat
                  value={parseInt(nom) + 5940}
                  displayType={"text"}
                  thousandSeparator=","
                  prefix={"Rp. "}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex space-x-2">
          <ButtonPrimary
            // loading={loading}
            type="submit"
            className="w-full bg-white text-gray-800 border-spacing-1 text-center py-2 mt-4"
          >
            <div
            className=" text-gray-500"
              onClick={() => {
                setConfirm(false);
                closeModal();
              }}
            >
              Batalkan
            </div>
          </ButtonPrimary>
          <ButtonPrimary
            loading={loading}
            type="submit"
            className="w-full bg-bluesaifillah text-center py-2 mt-4"
          >
            <div
              onClick={() => {
                setConfirm(true);
                const billing = new FormData();
          
                billing.append("billingId", 80); // hardcode billing for Ramadhan Infaq idBilling: 80
                billing.append("status", "Belum Lunas");
                billing.append("payment_method", "Xendit");
                billing.append("transaction_fee", 4000);
                billing.append("vat_fee", 440);
                billing.append("platform_fee", 1500);
                billing.append(
                  "description",
                  `Infaq Saifillah Ramadhan 2023 - ${name} - ${phone}`
                );
                billing.append("total_price", parseInt(nom) + 440 + 4000 + 1500);
                setLoading(true);
                axios
                  .post("https://digitalcity.id/api/publicorder2/", billing)
                  .then((res) => {
                    // window.open(res.data.payment_url);
                    setPayment(res.data.payment_url);
                    openModal2();
                  })
                  .catch((err) => {
                    if (err.response.data?.message) {
                      setErrorRegister(err.response.data.message);
                      setTimeout(() => {
                        setErrorRegister();
                      }, 8000);
                    }
                  })
                  .finally(() => {
                    setLoading(false);
                  });
                closeModal();
              }}
            >
              Lanjutkan Pembayaran
            </div>
          </ButtonPrimary>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen2}
        onRequestClose={closeModal2}
        style={customStyles}
        ariaHideApp={false}
      >
        <div className="w-full h-96" />
        <div className="z-50">
          <div className="flex items-center justify-end">
            <div onClick={closeModal2} className="cursor-pointer">
              X
            </div>
          </div>
          <div>
            <iframe
              src={payment}
              title="W3Schools Free Online Web Tutorials"
              height="1000"
              width="100%"
            ></iframe>
          </div>
        </div>
      </Modal>
      <div className="w-full h-20" />
      <section className="max-w-6xl mx-auto">
        <div className="lg:flex mb-6">
          <div className="w-full lg:ml-5 mt-5 mb-20 lg:mt-0">
            <div className="bg-white shadow-lg px-5 rounded-xl py-5 ">
              <h2 className="font-bold text-center text-2xl">INFAQ RAMADHAN</h2>
              <form
                onSubmit={handleSubmit(onSubmit)}
                // className="relative row-span-2 mt-12 sm:mt-20 bg-white z-20 shadow-lg w-full border border-turquoise-normal p-7 rounded-lg"
              >
                <div className="space-y-6 mb-6">
                  <FormInputField
                    control={control}
                    name="name"
                    errors={errors}
                    title="Nama Donatur"
                    disabled={confirm}
                  />
                  <FormInputField
                    control={control}
                    name="phone"
                    errors={errors}
                    type="number"
                    title="Nomor Telepon"
                    disabled={confirm}
                  />
                  {/* <div className="flex flex-col">
                    <div className="text-gray-400">Jenis Donasi</div>
                    <Select
                      name="type"
                      options={[
                        {
                          value: "74",
                          label: "Wakaf",
                        },
                        {
                          value: "75",
                          label: "Infaq",
                        },
                        {
                          value: "76",
                          label: "Donasi Operasional",
                        },
                        {
                          value: "77",
                          label: "Donasi Acara Khusus",
                        },
                        {
                          value: "80",
                          label: "Infaq Ramadhan 1444H",
                        },
                      ]}
                      onChange={(e) => {
                        setTipe(e.value);
                      }}
                      placeholder="Silahkan pilih jenis donasi"
                    />
                  </div> */}
                  <FormInputField
                    control={control}
                    errors={errors}
                    name="nominal"
                    title="Nominal"
                    disabled={confirm}
                  />
                </div>
                {errorRegister ? (
                  <ErrorMessage>{errorRegister}</ErrorMessage>
                ) : null}
                <ButtonPrimary
                  loading={loading}
                  disabled={confirm}
                  type="submit"
                  className="w-full bg-bluesaifillah-normal text-center py-2 mt-4"
                >
                  {confirm ? 'PEMBAYARAN TELAH DIPROSES': 'DONASI SEKARANG'} 
                </ButtonPrimary>
                {confirm && <p className=" mt-6">
                  Terima kasih telah melakukan proses pembayaran. Untuk mengetahui lebih detail tentang kegiatan program Ramadhan bisa menuju ke <a href="/ramadhan" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">LINK BERIKUT</a>
                </p>}
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default InfaqRamadhan;

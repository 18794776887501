import React from "react";

const Profile = () => {
  return (
    <>
      <div className="mt-12 p-6">
        <h1 className=" text-2xl font-bold">Tentang Masjid Saifillah</h1>
        <br></br>
        <p className=" text-xl">
          Masjid Saifillah dinaungi oleh <i>Yayasan Keluarga Muslim Sevilla</i>{" "}
          yang didirikan berdasarkan Akta Notaris Siti Prihatin Yulianti, SH,
          M.Kn No.2 Tanggal 06 Maret 2014, yang disyahkan oleh Menteri Hukum dan
          Hak Asasi Manusia No. AHU-05161.50.10.2014 tanggal 29 Agustus 2014.
        </p>
        <br></br>
        <p className=" text-xl">
          Yayasan mempunyai maksud dan tujuan di bidang keagamaan, sosial, dan
          Pendidikan. Yayasan menyelenggarakan kegiatan:
        </p>
        <div>
        <ul className=" list-disc p-6  text-xl">
          <li><strong>Bidang Keagamaan</strong> : mendirikan sarana ibadah Masjid , melaksanakan shalat berjama'ah, pengajian Al Quran dan Hadits, tausiyah dan kegiatan peribadatan umat muslim sevilla lainnya.</li>
          <li>
            <strong>Bidang Sosial</strong> : pengumpulan  dana dan menyalurkan dana sosial
            (zakat, infaq, sodaqoh).
          </li>
          <li>
            <strong>Bidang Pendidikan</strong> : mengelola pendidikan non formal Taman Pendidikan
            Al-Qur’an (TPA).
          </li>
        </ul>
        </div>
        <p className="text-xl">
        Struktur Organisasi Yayasan Periode Tahun 2016-2019 (Berdasarkan SK
        Pembina Yayasan Keluarga Muslim Sevilla Nomor : 01/PMB-KMS/II/2016
        tanggal 10 Februari 2016).<br></br>Perubahan DPP Pengurus dan Pengawas Yayasan KMS <br></br><br></br><u>Pembina</u>
        <ul className=" list-decimal p-6">
          <li>Madi</li>
          <li>Djauhari</li>
          <li>Robbi Firli</li>
        </ul>
        <u>Pengawas</u><br></br>
        <ul className=" list-decimal p-6">
          <li>Ustrinarto</li>
          <li>Ir. Ferry John Fadli</li>
          <li>Indra Lukman</li>
        </ul>
        
        <u>Pengurus</u> 
        <ul className=" list-decimal p-6">
          <li>Erisnandar : Ketua Umum</li>
          <li>Wana Sherpa : Sekretaris Umum </li>
          <li>Murdianto ST : Bendahara Umum</li>
          <li>Agung Triyono Safar : Ketua DKM Saifillah</li>
          <li>Muhammad Faturrachman : Ketua Layanan Umum</li>
          <li>Boedi Santoso : Ketua Ekonomi Kreatif</li>
        </ul>
        </p>
      </div>
    </>
  );
};

export default Profile;

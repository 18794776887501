import React from 'react';

const Footer = () => {
  return (
    <footer id="footer" className=" bg-bluesaifillah-normal">
      <div className="container mx-auto py-4 text-center">
        <p className="text-white">© 2023 Masjid Saifillah BSD</p>
      </div>
    </footer>
  );
};

export default Footer;

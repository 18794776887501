import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Donasi from './donasi/Donasi';
import Home from './home/Home';
import Kajian from './kajian/Kajian';
import Profile from './profile/Profile';
import Ramadhan from './ramadhan/Ramadhan';
import JadwalImamTarawih from './ramadhan/JadwalImamTarawih';
import InfaqRamadhan from './ramadhan/InfaqRamadhan';
import PraRamadhanAnak from './ramadhan/PraRamadhanAnak';
import Kegiatan from './kegiatan/Kegiatan';
import Berita from './berita/Berita';
import DetailBerita from './berita/DetailBerita';
import TambahBerita from './berita/TambahBerita';
import Login from './account/Login';
import PrivateRoute from '../components/PrivateRoute';
import EditBerita from './berita/EditBerita';
import ImamCilik from './ramadhan/ImamCilik';
import PejuangSubuh from './pejuang-subuh/PejuangSubuh';
import Privacy from './privacy';

const Router = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/kajian' element={<Kajian />} />
      <Route path='/pejuang-subuh' element={<PejuangSubuh />} />
      <Route path='/ramadhan-anak' element={<Kegiatan />} />
      <Route path='/profile' element={<Profile />} />
      <Route path='/info' element={<Profile />} />
      <Route path='/artikel' element={<Profile />} />
      <Route path='/donasi' element={<Donasi />} />
      {/* Account */}
      <Route path='/login' element={<Login />} />

      {/* Berita */}
      <Route path='/berita' element={<Berita />} />
      <Route path='/berita/:id/:title' element={<DetailBerita />} />

      <Route
        path='/tambah-berita'
        element={
          <PrivateRoute>
            <TambahBerita />
          </PrivateRoute>
        }
      />
      <Route
        path='/edit-berita/:id'
        element={
          <PrivateRoute>
            <EditBerita />
          </PrivateRoute>
        }
      />

      <Route path='/ramadhan' element={<Ramadhan />} />
      <Route path='/infaq-ramadhan' element={<InfaqRamadhan />} />
      {/* <Route path='/pra-ramadhan' element={<PraRamadhanAnak />} /> */}
      <Route path='/tarawih' element={<JadwalImamTarawih />} />
      <Route path='/imam-cilik' element={<ImamCilik />} />
      <Route path='/privacy' element={<Privacy />} />
    </Routes>
  );
};

export default Router;

import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

const FormInputField = ({
  name,
  title,
  placeholder = '',
  control,
  errors,
  type,
  tooltip,
  disabled,
}) => {
  const [isFocused, setIsFocused] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <div className='flex flex-col'>
            <label htmlFor={field.name} className='text-gray-400'>
              {title}
            </label>
            <div className='flex flex-row relative align-middle justify-center'>
              {name === 'phone' && (
                <p className='mr-2 mt-2 whitespace-nowrap'>+62</p>
              )}
              {tooltip && isFocused ? (
                <div className='absolute right-[100%] w-40 bg-gray-700 rounded-md p-3'>
                  <p className='text-white'>{tooltip}</p>
                </div>
              ) : null}
              <input
                {...field}
                className={`w-full decoration border-transparent focus-visible:outline-none ${
                  disabled ? 'bg-gray-200' : ''
                }`}
                autoComplete='off'
                disabled={disabled}
                placeholder={placeholder}
                name={field.name}
                id={field.name}
                onFocus={() => {
                  if (tooltip) {
                    setIsFocused(true);
                  }
                }}
                onBlur={() => {
                  if (tooltip) {
                    setIsFocused(false);
                  }
                }}
                type={
                  type === 'password'
                    ? showPassword
                      ? 'text'
                      : 'password'
                    : type === 'date'
                    ? 'date'
                    : type === 'number'
                    ? 'number'
                    : 'text'
                }
              />
              {type === 'password' && (
                <div
                  onClick={() => showPasswordToggle()}
                  className='cursor-pointer'
                >
                  {showPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
                </div>
              )}
            </div>
            <div className='h-0.5 mt-1 bg-gray-200'></div>
            {errors[field.name] && (
              <p className='text-red-400 mt-1 text-sm'>
                {errors[field.name]?.message}
              </p>
            )}
          </div>
        );
      }}
    />
  );
};

export default FormInputField;

import axios from 'axios'
import { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import { useNavigate } from 'react-router-dom'
import { FiArrowRightCircle, FiEdit } from 'react-icons/fi'
import { getUserToken } from '../../utils/types'

const Berita = () => {
  const [news, setNews] = useState([])
  const [error, setError] = useState('')
  const [showFullText, setShowFullText] = useState(false)
  const [selectedNews, setSelectedNews] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    const dataNews = async () => {
      try {
        const res = await axios.get(
          'https://digitalcity.id/api/newsbyidcommunity/52/'
        )
        console.log(res.data)
        setNews(res.data)
      } catch (err) {
        console.log(err)
      }
    }
    dataNews()
  }, [])

  const handleClick = (index) => {
    setSelectedNews(index)
    setShowFullText(true)
  }

  return (
    <section className="my-12">
      <div className="px-6 md:py-8 py-8 md:block">
        <p className=" text-2xl text-center text-bold">News</p>
      </div>
      <div className="my-6 w-full md:gap-5 flex flex-wrap justify-center">
        {news.length !== 0 ? (
          news.map((value, index) => {
            // console.log(value)
            const getToken = getUserToken()
            return (
              <div
                key={value.id}
                className="md:w-[280px] w-[170px] rounded overflow-hidden drop-shadow-xl bg-white mb-5 mx-2 cursor-pointer"
                onClick={() => navigate(`/berita/${value.id}/${value.title.replace(/ /g,"-")}`)}

              >
                <img
                  className="w-full"
                  src={value.image}
                  alt="Sunset in the mountains"
                />
                <div className="md:px-6 px-2 py-4">
                  <div className="font-bold md:text-xl text-sm mb-2 flex justify-between items-center">
                    <p>{value?.title}</p>
                    {getToken ? (
                      <FiEdit
                        color="rgb(21,110,152)"
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(`/edit-berita/${value.id}`, {
                            state: { news: value },
                          })
                        }
                      />
                    ) : null}
                  </div>
                  <div
                    className="text-gray-700 md:text-sm text-xs w-auto"
                    onClick={() => handleClick(index)}
                  >
                    {parse(value.content.slice(0, 100) + '...')}
                  </div>
                  {/* <div className="flex justify-center gap-1 items-center my-2">
                    <FiArrowRightCircle
                      color="rgb(21,110,152)"
                      className="cursor-pointer md:text-2xl text-xl"
                    />
                  </div> */}
                </div>
              </div>
            )
          })
        ) : (
          <div>Belum Ada Berita</div>
        )}
      </div>
      {showFullText && (
        <div className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-70 z-50">
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:w-2/5 w-3/4 max-h-4/5 overflow-y-scroll bg-white rounded-md p-4">
            <div className="font-bold text-xl mb-2">
              {news[selectedNews].title}
            </div>
            <div className="text-gray-700 text-base">
              {parse(news[selectedNews].content)}
            </div>
            <button
              className="mt-4 w-full h-auto bg-bluesaifillah-normal text-white p-3 rounded-full"
              onClick={() => setShowFullText(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      {/* {!showFullText && (
        <div className="flex justify-center">
          <button
            className="w-auto h-auto bg-bluesaifillah-normal text-white p-3 rounded-full"
            onClick={() => navigate('/berita')}
          >
            Lihat Lebih Banyak
          </button>
        </div>
      )} */}
    </section>
  )
}

export default Berita

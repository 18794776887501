import React from "react";

const JadwalImamTarawih = () => {
  return (
    <>
      <div className="mt-11 p-6 lg:mx-20">
        <h2 className="text-4xl font-extrabold dark:text-white">
          Jadwal Imam Tarawih
        </h2>
        <p className=" pt-16 pb-6">
          <i>*Asumsi Ramadhan hari ke-1 kamis 23 Maret 2023</i>
        </p>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <table className="min-w-full text-left text-sm font-light">
                  <thead className="border-b font-medium dark:border-neutral-500">
                    <tr>
                      <th scope="col" className="px-6 py-4">
                        No
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Tgl Masehi
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Hari
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Tgl Hijriah
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Imam
                      </th>
                      <th scope="col" className="px-6 py-4">
                        QS Bacaan
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Keterangan
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">1</td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        22-Mar
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Rabu
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-1
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust M. Akbar
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 1
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        ¼ juz tarawih, sisanya dibacakan tasmi
                      </td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">2</td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        23-Mar
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Kamis
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-2
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. Iqbal Jundi
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 2
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">3</td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        24-Mar
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Jumat
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-3
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Syekh Waleed Al Yamani
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 3
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">4</td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        25-Mar
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Sabtu
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-4
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. Ihsan Ashari
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 4
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Kultum Ustadz M. Habibi, Lc Mpd
                      </td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">5</td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        26-Mar
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ahad
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-5
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Syekh Waleed Al Yamani
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 5
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">6</td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        27-Mar
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Senin
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-6
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. Iqbal Jundi
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 6
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">7</td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        28-Mar
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Selasa
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-7
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. M. Akbar
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 7
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">8</td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        29-Mar
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Rabu
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-8
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. M. Akbar
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 8
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">9</td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        30-Mar
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Kamis
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-9
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. M. Akbar
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 9
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        10
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        31-Mar
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Jumat
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-10
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Syekh Waleed Al Yamani
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 10
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        11
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        01-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Sabtu
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-11
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. Ihsan Ashari
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 11
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Buka puasa bersama
                      </td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        12
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        02-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ahad
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-12
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        IMAM TAMU
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 12
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        13
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        03-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Senin
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-13
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. Iqbal Jundi
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 13
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        14
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        04-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Selasa
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-14
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. M. Akbar
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 14
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        15
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        05-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Rabu
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-15
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. M. Akbar
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 15
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        16
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        06-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Kamis
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-16
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. Iqbal Jundi
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 16
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        17
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        07-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Jumat
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-17
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. Nawir
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 17
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        18
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        08-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Sabtu
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-18
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. Ihsan Ashari
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 18
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Kultum Ustadz Syahrun Shoim, Lc
                      </td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        19
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        09-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ahad
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-19
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Syekh Waleed Al Yamani
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 19
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        20
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        10-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Senin
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-20
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. Iqbal Jundi
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 20
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        21
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        11-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Selasa
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-21 Tarawih
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. M. Akbar
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 21
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        12-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Rabu dini hari
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Tahajjud 1
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. M. Akbar
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Tahajud 1 rakaat 1 halaman, Surat Bebas
                      </td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        22
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        12-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Rabu
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-22 Tarawih
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. Ihsan Ashari
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 22
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        13-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Kamis dini hari
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Tahajjud 2
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. Nawir
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        23
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        13-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Kamis
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-23 Tarawih
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. Iqbal Jundi
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 23
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        14-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Jumat dini hari
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Tahajjud 3
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. M. Akbar
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        24
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        14-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Jumat
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-24 Tarawih
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Syekh Waleed Al Yamani
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 24
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        15-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Sabtu dini hari
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Tahajjud 4
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. Ihsan Ashari
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        25
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        15-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Sabtu
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-25 Tarawih
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. Ihsan Ashari
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 25
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Kultum Ustadz Hendro Prasetyo, Spdi
                      </td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        16-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ahad dini hari
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Tahajjud 5
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Syekh Waleed Al Yamani
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        26
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        16-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ahad
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-26 Tarawih
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Syekh Waleed Al Yamani
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 26
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        17-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Senin dini hari
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Tahajjud 6
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. M. Akbar
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        27
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        17-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Senin
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-27 Tarawih
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. Iqbal Jundi
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 27
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        18-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Selasa dini hari
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Tahajjud 7
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. Iqbal Jundi
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        28
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        18-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Selasa
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-28 Tarawih
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. Nawir
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 28
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        19-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Rabu dini hari
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Tahajjud 8
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Ust. Ihsan Ashari
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        29
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        19-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Rabu
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-29 Tarawih
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                      Ust. Ihsan Ashari
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 29
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">-</td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        20-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Kamis dini hari
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Tahajjud 9
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                      Ust. Iqbal Jundi
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        30
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        20-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Kamis
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Malam ke-30 Tarawih
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                      Ust. Nawir
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Juz 30
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium"><i>Jika Ramadhan 30 Hari</i></td>
                    </tr>
                    <tr class="border-b dark:border-neutral-500">
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        21-Apr
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Jumat dini hari
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                        Tahajjud 10
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium">
                      Ust. Ihsan Ashari
                      </td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                      <td class="whitespace-nowrap px-6 py-4 font-medium"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JadwalImamTarawih;

/* eslint-disable react/react-in-jsx-scope */

import { Navigate } from "react-router-dom";
import { getUserToken } from "../utils/types";

function PrivateRoute({ children }) {
  const auth = getUserToken();
  return auth ? children : <Navigate to="/login" />;
}
export default PrivateRoute;

import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { getUserData, getUserToken } from '../utils/types';
import ButtonPrimary from './ButtonPrimary';
import { RxCross2 } from 'react-icons/rx';
import { Menu, Transition } from '@headlessui/react';

const Navbar = () => {
  const userToken = getUserToken();
  const userData = getUserData();
  const navigate = useNavigate();
  const [hamburgOpen, setHamburgOpen] = useState(false);

  const NavigationLink = ({ children, link }) => {
    return (
      <li className="py-4 px-10 sm:py-0 sm:px-0 sm:ml-4">
        <NavLink
          to={link}
          className={({ isActive }) =>
            `${isActive ? ' text-bluesaifillah-normal font-bold' : ''}`
          }
          onClick={() => {
            setHamburgOpen(false);
          }}
        >
          {children}
        </NavLink>
      </li>
    );
  };

  return (
    <>
      <nav className="shadow-md rounded-b-2xl fixed w-full bg-white z-50">
        <div className="flex flex-row justify-between items-center container mx-auto px-6 py-4">
          <NavLink to="/">
            <img
              className=" w-12 object-contain"
              src={require('../assets/logo_masjid_saifillah.png')}
              alt="Masjid Saifillah BSD"
            />
          </NavLink>
          <div className="flex flex-row items-center relative">
            <ul
              className={`fixed sm:static top-0 left-0 -translate-x-[100%] ${
                hamburgOpen && '-translate-x-0'
              } flex flex-col bg-white sm:bg-transparent duration-300 ease-in-out h-full min-w-[20rem] sm:min-w-[0] py-10 sm:py-0 z-50 divide-y sm:translate-x-0 sm:divide-y-0 sm:flex-row sm:items-center`}
            >
              <li className="flex flex-row items-center justify-between px-10 py-8 sm:hidden">
                <NavLink to="/">
                  <img
                    className="w-12 object-contain"
                    src={require('../assets/logo_masjid_saifillah.png')}
                    alt="educourse"
                  />
                </NavLink>
                <button
                  type="button"
                  onClick={() => {
                    setHamburgOpen(false);
                  }}
                >
                  <RxCross2 size={30} />
                  
                </button>
              </li>
              <NavigationLink link="/">Home</NavigationLink>
              {/* <NavigationLink link="/profile">Profile</NavigationLink>
              <NavigationLink link="/info">Info</NavigationLink>
              <NavigationLink link="/artikel">Artikel</NavigationLink> */}
              {userToken && (
                <NavigationLink link="/dashboard">Dashboard</NavigationLink>
              )}
              {userToken && (
                <NavigationLink link="/tambah-berita">Add News</NavigationLink>
              )}
              {userToken ? null : (
                <li className="px-10 py-6 sm:p-0 sm:ml-4">
                  <ButtonPrimary
                    link="/login"
                    func={() => {
                      setHamburgOpen(false);
                    }}
                  >
                    Log In
                  </ButtonPrimary>
                </li>
              )}
            </ul>
            <div
              id="hamburger-button"
              className={`relative sm:hidden hamburger ${
                hamburgOpen && 'open'
              }`}
              onClick={() => {
                setHamburgOpen(!hamburgOpen);
              }}
            >
              <span className="hamburger-top"></span>
              <span className="hamburger-middle"></span>
              <span className="hamburger-bottom"></span>
            </div>
            {userData ? (
              <Menu>
                <Menu.Button className="w-8 border rounded-full border-turquoise-normal ml-4">
                  <img
                    className="w-8 object-contain"
                    src={require('../assets/user-no-image.png')}
                    alt="educourse"
                  />
                </Menu.Button>
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Menu.Items className="bg-white absolute overflow-hidden right-0 top-8 rounded-lg flex flex-col justify-end text-right whitespace-nowrap shadow-md focus:outline-none">
                    <Menu.Item
                      className="px-5 py-3 bg-yellow-normal text-sm text-white"
                      disabled
                    >
                      <span>{userData.email}</span>
                    </Menu.Item>
                    <Menu.Item className="p-3 hover:text-yellow-normal duration-300">
                      <a href="/myprofile">My Profile</a>
                    </Menu.Item>
                    <div className="w-full h-[1px] bg-gray-200"></div>
                    <Menu.Item className="p-3 text-right hover:text-yellow-normal duration-300">
                      <button
                        onClick={() => {
                          setHamburgOpen(false);
                          localStorage.clear();
                          navigate('/');
                        }}
                      >
                        Log Out
                      </button>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            ) : null}
          </div>
        </div>
      </nav>
      <div
        className={`fixed sm:hidden bg-black duration-300 ease-in-out bg-opacity-0 pointer-events-none ${
          hamburgOpen && '!bg-opacity-20 !pointer-events-auto'
        } z-40 w-full h-full`}
        onClick={() => {
          setHamburgOpen(false);
        }}
      ></div>
    </>
  );
};

export default Navbar;

import React, { useEffect, useState } from 'react';
import { Carousel } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import News from '../../components/News';
import axios from 'axios';

const Home = () => {
  const [news, setNews] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const dataNews = async () => {
      try {
        const res = await axios.get(
          'https://digitalcity.id/api/newsbyidcommunity/52/'
        );

        setNews(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    dataNews();
  }, []);

  return (
    <>
      <section id='header' className='mt-8 w-full'>
        <div className=' bg-bluesaifillah-normal h-72 md:h-96'>
          <Carousel>
            <img
              className=' object-contain h-96 w-full'
              src={require('../../assets/home/ramadhan.png')}
              alt='...'
            />
            <img
              className=' object-contain h-96 w-full'
              src={require('../../assets/home/header-background.png')}
              alt='...'
            />
            {news
              .filter((ne) => ne.is_banner)
              .map((item, i) => (
                <div
                  key={i}
                  onClick={() =>
                    navigate(
                      `/berita/${item.id}/${item.title.replace(/ /g, '-')}`
                    )
                  }
                >
                  <img
                    className=' object-contain h-96 w-full'
                    src={item?.image}
                    alt='...'
                  />
                </div>
              ))}
          </Carousel>
        </div>
      </section>
      <section id='activities' className='my-6 w-full'>
        <div className='px-6 py-8 hidden md:block'>
          <p className=' text-2xl text-center text-bold'>
            Aktifitas dan Kegiatan
          </p>
        </div>
        <div className='grid grid-cols-4 gap-4'>
          <div
            className='flex flex-col align-middle p-3'
            onClick={() => navigate('/kajian')}
          >
            <div className='flex justify-center'>
              <img
                className='w-12 md:w-20  h-12 md:h-20'
                src={require('../../assets/home/kajian.png')}
                alt='Kajian'
              />
            </div>
            <div className='pt-3'>
              <h5 className=' text-xs md:text-xl font-semibold tracking-tight text-gray-800 text-center '>
                Kajian Rutin
              </h5>
            </div>
            <div className='hidden md:block '>
              <p className=' leading-normal text-gray-700 text-center '>
                Pengajian yang dilakukan secara rutin dengan mengundang ustad
                untuk mengisi kajian.
              </p>
            </div>
          </div>
          <div
            className='flex flex-col align-middle  p-3'
            onClick={() => navigate('/pejuang-subuh')}
          >
            <div className='flex justify-center'>
              <img
                className='w-10 md:w-16  h-12 md:h-20'
                src={require('../../assets/home/masjid.png')}
                alt='Masjid'
              />
            </div>
            <div className='pt-3'>
              <h5 className='text-xs md:text-xl font-semibold tracking-tight text-gray-800 text-center '>
                Pejuang Subuh
              </h5>
            </div>
            <div className='hidden md:block '>
              <p className=' leading-normal text-gray-700 text-center'>
                Gerakan membiasakan anak muda untuk menghadiri solat subuh
                berjamaah di masjid.
              </p>
            </div>
          </div>
          <div className='flex flex-col align-middle  p-3'>
            <div className='flex justify-center'>
              <img
                className='w-12 md:w-20 h-12 md:h-20'
                src={require('../../assets/home/quran.png')}
                alt='Quran'
              />
            </div>
            <div className='pt-3'>
              <h5 className='text-xs md:text-xl font-semibold tracking-tight text-gray-800 text-center '>
                TPA
              </h5>
            </div>
            <div className='hidden md:block '>
              <p className=' leading-normal text-gray-700 text-center'>
                Taman pendidikan Alqur'an untuk anak-anak yang dilakukan rutin
                tiap sore secara periodik.
              </p>
            </div>
          </div>
          <div className='flex flex-col align-middle  p-3'>
            <div className='flex justify-center'>
              <img
                className='w-12 md:w-20 h-12 md:h-20'
                src={require('../../assets/home/silaturahmi.png')}
                alt='Silaturahmi'
              />
            </div>
            <div className='pt-3'>
              <h5 className='text-xs md:text-xl font-semibold tracking-tight text-gray-800 text-center '>
                Ukhuwah
              </h5>
            </div>
            <div className='hidden md:block '>
              <p className='leading-normal text-gray-700 text-center '>
                Halal-bihalal untuk mempererat tali silaturahmi antar jamaah
                masjid.
              </p>
            </div>
          </div>
          <div
            className='flex flex-col align-middle  p-3'
            onClick={() => navigate('/ramadhan')}
          >
            <div className='flex justify-center'>
              <img
                className='w-12 md:w-20 h-12 md:h-20'
                src={require('../../assets/home/ramadhan-icon.png')}
                alt='Ramadhan'
              />
            </div>
            <div className='pt-3'>
              <h5 className='text-xs md:text-xl font-semibold tracking-tight text-gray-800 text-center '>
                Ramadhan
              </h5>
            </div>
            <div className='hidden md:block '>
              <p className='leading-normal text-gray-700 text-center '>
                Berkegiatan di bulan penuh berkah bersama program masjid
                Saifillah.
              </p>
            </div>
          </div>
          <div
            className='flex flex-col align-middle  p-3'
            onClick={() => navigate('/donasi')}
          >
            <div className='flex justify-center'>
              <img
                className='w-10 md:w-16 h-12 md:h-20'
                src={require('../../assets/home/infaq.png')}
                alt='Infaq & Sedekah'
              />
            </div>
            <div className='pt-3'>
              <h5 className='text-xs md:text-xl font-semibold tracking-tight text-gray-800 text-center '>
                Infaq
              </h5>
            </div>
            <div className='hidden md:block '>
              <p className='leading-normal text-gray-700 text-center '>
                Berikan infaq & sedekah terbaik untuk membangun umat.
              </p>
            </div>
          </div>
          <div className='flex flex-col align-middle  p-3'>
            <div className='flex justify-center'>
              <img
                className='w-10 md:w-16 h-12 md:h-20'
                src={require('../../assets/home/info.png')}
                alt='Info'
              />
            </div>
            <div className='pt-3'>
              <h5 className='text-xs md:text-xl font-semibold tracking-tight text-gray-800 text-center '>
                Informasi
              </h5>
            </div>
            <div className='hidden md:block '>
              <p className='leading-normal text-gray-700 text-center '>
                Seputar info untuk jamaah masjid.
              </p>
            </div>
          </div>
        </div>
      </section>
      <News />
      {/* <section className="pt-20 lg:pt-[120px] pb-10 lg:pb-20">
        <div className="">
          <div className="flex flex-wrap justify-center -mx-4">
            <div className="w-full px-4">
              <div className="text-center mx-auto mb-[60px] lg:mb-20 max-w-[510px]">
                <p className=" text-3xl mb-6">Informasi</p>
                <p className="text-base text-body-color">
                  Simak update berita terkini yang berisi tentang berbagai macam
                  artikel atau informasi
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-1/4 lg:w-1/6 px-4">
              <div className="max-w-[370px] mx-auto mb-10">
                <div className="rounded overflow-hidden mb-8">
                  <img
                    src="https://cdn.tailgrids.com/1.0/assets/images/blogs/blog-01/image-01.jpg"
                    className="w-full"
                  />
                </div>
                <div>
                  <span
                    className="
                     bg-primary
                     rounded
                     inline-block
                     text-center
                     py-1
                     px-4
                     text-xs
                     leading-loose
                     font-semibold
                     text-white
                     mb-5
                     "
                  >
                    Dec 22, 2023
                  </span>
                  <h3>
                    <a
                      href="#"
                      className="
                        font-semibold
                        text-xl
                        sm:text-2xl
                        lg:text-xl
                        xl:text-2xl
                        mb-4
                        inline-block
                        text-dark
                        hover:text-primary
                        "
                    >
                      Meet AutoManage, the best AI management tools
                    </a>
                  </h3>
                  <p className="text-base text-body-color">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/4 lg:w-1/6 px-4">
              <div className="max-w-[370px] mx-auto mb-10">
                <div className="rounded overflow-hidden mb-8">
                  <img
                    src="https://cdn.tailgrids.com/1.0/assets/images/blogs/blog-01/image-02.jpg"
                    alt="image"
                    className="w-full"
                  />
                </div>
                <div>
                  <span
                    className="
                     bg-primary
                     rounded
                     inline-block
                     text-center
                     py-1
                     px-4
                     text-xs
                     leading-loose
                     font-semibold
                     text-white
                     mb-5
                     "
                  >
                    Mar 15, 2023
                  </span>
                  <h3>
                    <a
                      href="#"
                      className="
                        font-semibold
                        text-xl
                        sm:text-2xl
                        lg:text-xl
                        xl:text-2xl
                        mb-4
                        inline-block
                        text-dark
                        hover:text-primary
                        "
                    >
                      How to earn more money as a wellness coach
                    </a>
                  </h3>
                  <p className="text-base text-body-color">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/4 lg:w-1/6 px-4">
              <div className="max-w-[370px] mx-auto mb-10">
                <div className="rounded overflow-hidden mb-8">
                  <img
                    src="https://cdn.tailgrids.com/1.0/assets/images/blogs/blog-01/image-03.jpg"
                    alt="image"
                    className="w-full"
                  />
                </div>
                <div>
                  <span
                    className="
                     bg-primary
                     rounded
                     inline-block
                     text-center
                     py-1
                     px-4
                     text-xs
                     leading-loose
                     font-semibold
                     text-white
                     mb-5
                     "
                  >
                    Jan 05, 2023
                  </span>
                  <h3>
                    <a
                      href="#"
                      className="
                        font-semibold
                        text-xl
                        sm:text-2xl
                        lg:text-xl
                        xl:text-2xl
                        mb-4
                        inline-block
                        text-dark
                        hover:text-primary
                        "
                    >
                      The no-fuss guide to upselling and cross selling
                    </a>
                  </h3>
                  <p className="text-base text-body-color">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/4 lg:w-1/6 px-4">
              <div className="max-w-[370px] mx-auto mb-10">
                <div className="rounded overflow-hidden mb-8">
                  <img
                    src="https://cdn.tailgrids.com/1.0/assets/images/blogs/blog-01/image-03.jpg"
                    alt="image"
                    className="w-full"
                  />
                </div>
                <div>
                  <span
                    className="
                     bg-primary
                     rounded
                     inline-block
                     text-center
                     py-1
                     px-4
                     text-xs
                     leading-loose
                     font-semibold
                     text-white
                     mb-5
                     "
                  >
                    Jan 05, 2023
                  </span>
                  <h3>
                    <a
                      href="#"
                      className="
                        font-semibold
                        text-xl
                        sm:text-2xl
                        lg:text-xl
                        xl:text-2xl
                        mb-4
                        inline-block
                        text-dark
                        hover:text-primary
                        "
                    >
                      The no-fuss guide to upselling and cross selling
                    </a>
                  </h3>
                  <p className="text-base text-body-color">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Home;

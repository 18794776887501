import React from "react";

const Ramadhan = () => {
  return (
    <>
      <div className="mt-11 p-6 lg:mx-20">
        <h2 className="text-4xl font-extrabold dark:text-white">
          Program Ramadhan 1444H
        </h2>
        <div class="flex justify-center mt-10 mb-10">
          <img
                    src={require("../../assets/ramadhan/ramadhansevilla.png")}
                    alt="Masjid" className="object-cover"/>
        </div>
        <p className="my-4 text-lg">
          Assalamu’alaikum warahmatullahi wabarakatuh,<br></br> <br></br>Segala
          puji bagi Allah SWT, Rabb semesta alam yang telah menjadikan bumi
          sebagai hamparan dan menurunkan rahmat bagi sekalian alam. Sholawat
          dan salam kita sampaikan kepada junjungan kita Nabi Muhammad SAW.
          Berikut kami sampaikan rencana kegiatan yang akan dilaksanakan dalam
          menyambut dan selama bulan Ramadhan 1444H, antara lain sebagai
          berikut:
        </p>
        <p className=" font-bold">A. Kegiatan Pra-Ramadhan</p>
        <p>
          <ul className=" list-decimal px-6 py-3">
            <li>
              Pembagian bingkisan pra-ramadhan bagi petugas security, petugas
              kebersihan, pegawai PWCS dan MT Khadisa{" "}
            </li>
            <li>Ramadhan Istimewa Anak Saifillah (RISALAH) <a href="/pra-ramadhan" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">(Lihat DETAIL)</a></li>
          </ul>
        </p>
        <p className=" font-bold">
          B. Shalat Tarawih - Tadarus - Qiyamul lail - Itikaf
        </p>
        <p>
          <ul className=" list-decimal px-6 py-3">
            <li>Sholat tarawih 11 raka’at dengan bacaan ¼ juz. <a href="/tarawih" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">(Lihat jadwal Imam Tarawih)</a></li>
            <li>
              Qiyamullail di 10 malam akhir ramadhan dengan bacaan 1 lembar
              per-raka’at
            </li>
          </ul>
        </p>
        <p className=" font-bold">C. Kajian Keislaman / Ta’lim</p>
        <p>
          <ul className=" list-decimal px-6 py-3">
            <li>Ta’lim Subuh Sabtu-Ahad berjalan seperti biasa</li>
            <li>
              Tasmi’ dilaksanakan setelah Sholat Tarawih di Masjid dan Online
              bagi yang ingin mengikuti dari rumah
            </li>
            <li>Kultum Tarawih akhir pekan</li>
            <li>Kajian remaja Hijrah</li>
          </ul>
        </p>
        <p className=" font-bold">D. Kegiatan Sosial</p>
        <p>
          <ul className=" list-decimal px-6 py-3">
            <li>
              Penyediaan sahur dan buka puasa harian untuk petugas security,
              petugas masjid Saifillah dan musafir
            </li>
            <li>
              Paket bingkisan lebaran untuk petugas security dan petugas
              kebersihan.
            </li>
            <li>
              Baksos sumbangan untuk panti asuhan, masjid sekitar & dhuafa.
            </li>
            <li>
              Paket THR assatidz Assakinah, Imam masjid, karyawan masjid dan
              asatidz TPA
            </li>
            <li>
              Penerimaan dan penyaluran Zakat, Infaq dan Shadaqah bekerjasama
              dengan IZI (inisiatif Zakat Indonesia)
            </li>
          </ul>
        </p>
        <p className=" font-bold">E. Lain-lain</p>
        <p>
          <ul className=" list-decimal px-6 py-3">
            <li>Buka Puasa Bersama</li>
            <li>Halal Bihalal.</li>
            <li>Program Muadzin & Imam Cilik (Pendaftaran  <a href="/imam-cilik" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Klik di sini</a>)</li>
          </ul>
        </p>
        <p>
        Untuk mendukung kelancaran dalam pelaksanaan kegiatan Ramadhan, kami mengajak para jamaah untuk berkontribusi untuk melakukan INFAQ pada <a href="/infaq-ramadhan" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">LINK BERIKUT</a>
        </p>
      </div>
    </>
  );
};

export default Ramadhan;

import React from 'react';

const ErrorMessage = ({ children, className }) => {
  return (
    <div
      className={`bg-red-50 rounded-lg p-3 border border-red-400 ${className}`}
    >
      <p className="text-sm text-red-500 break-words">{children}</p>
    </div>
  );
};

export default ErrorMessage;

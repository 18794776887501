import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import FormInputField from '../../components/form/FormInputField';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ButtonPrimary from '../../components/ButtonPrimary';
import { NumericFormat } from 'react-number-format';
import Modal from 'react-modal';
import ErrorMessage from '../../components/ErrorMessage';
import Select from 'react-select';

import axios from 'axios';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const PejuangSubuh = () => {
  const [errorRegister, setErrorRegister] = useState();
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const [name, setName] = useState(null);
  const [blok, setBlok] = useState(null);
  const [phone, setPhone] = useState('');
  const [age, setAge] = useState('TK~SD');
  const [gender, setGender] = useState('PEREMPUAN');
  const [size, setSize] = useState('');

  const [nominal, setNominal] = useState(50000);

  const [payment, setPayment] = useState('');

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen2, setIsOpen2] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal2 = () => {
    setIsOpen2(true);
  };

  const closeModal2 = () => {
    setIsOpen2(false);
  };

  //   SCHEMA
  const schema = yup
    .object({
      name: yup.string().required('Nama lengkap harus diisi'),
      blok: yup.string().required('Blok harus diisi'),
      phone: yup
        .number('nomor telepon harus berupa angka')
        .typeError('Nomor telepon harus berupa angka')
        .required('Nomor telepon harus diisi'),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      phone: '',
      blok: '',
      nominal: 0,
    },
  });

  const onSubmit = (e) => {
    setName(e.name);
    setBlok(e.blok);
    setPhone(e.phone);

    if (confirm) {
      const billing = new FormData();
      billing.append('billingId', 529);
      billing.append('status', 'Belum Lunas');
      billing.append('payment_method', 'Xendit');
      billing.append('transaction_fee', 4000);
      billing.append('vat_fee', 440);
      billing.append('platform_fee', 1500);
      billing.append(
        'description',
        `Pejuang Subuh - Nama ${e.name} - Phone ${e.phone} - Blok Rumah ${e.blok} - Jenis Kelamin ${e.gender} - Ukuran Baju ${e.size} - Umur ${e.age}`
      );
      billing.append('total_price', e.nominal + 440 + 4000 + 1500);
      setLoading(true);

      axios
        .post('https://digitalcity.id/api/publicorder2/', billing)
        .then((res) => {
          window.open(res.data.payment_url);
        })
        .catch((err) => {
          if (err.response.data?.message) {
            setErrorRegister(err.response.data.message);
            setTimeout(() => {
              setErrorRegister();
            }, 8000);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      openModal();
    }
  };

  return (
    <div className='bg-gray-50'>
      {/* Modal Here */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >
        {/* modal body */}
        <div>
          <div className='flex items-center justify-end'>
            <div onClick={closeModal} className='cursor-pointer'>
              X
            </div>
          </div>
        </div>
        <div className='mb-6'>
          <h2 className='font-bold text-xl text-center text-bluesaifillah-normal mb-6'>
            KONFIRMASI
          </h2>
          <h2 className='font-bold text-center mb-3'>
            Mohon Mengecek kembali data Anda
          </h2>
        </div>
        <div className='flex justify-between'>
          <div>Biaya Pendaftaran</div>
          <div>
            {' '}
            <NumericFormat
              value={nominal}
              displayType={'text'}
              thousandSeparator=','
              prefix={'Rp. '}
            />
          </div>
        </div>
        <div className='flex justify-between'>
          <div>Biaya Transaksi</div>
          <div>
            {' '}
            <NumericFormat
              value={5940}
              displayType={'text'}
              thousandSeparator=','
              prefix={'Rp. '}
            />
          </div>
        </div>
        <div className='flex justify-between'>
          <div>Total</div>
          <div>
            {' '}
            <NumericFormat
              value={nominal + 5940}
              displayType={'text'}
              thousandSeparator=','
              prefix={'Rp. '}
            />
          </div>
        </div>
        <div className='flex space-x-2'>
          <ButtonPrimary
            // loading={loading}
            type='submit'
            className='w-full bg-white text-gray-800 border-spacing-1 text-center py-2 mt-4'
          >
            <div
              className=' text-gray-500'
              onClick={() => {
                setConfirm(false);
                closeModal();
              }}
            >
              Batalkan
            </div>
          </ButtonPrimary>
          <ButtonPrimary
            loading={loading}
            type='submit'
            className='w-full bg-bluesaifillah text-center py-2 mt-4'
          >
            <div
              onClick={() => {
                setConfirm(true);
                // closeModal();
                const billing = new FormData();

                billing.append('billingId', 529);
                // billing.append('userId', localdata.id);
                billing.append('status', 'Belum Lunas');
                billing.append('payment_method', 'Xendit');
                billing.append('transaction_fee', 4000);
                billing.append('vat_fee', 440);
                billing.append('platform_fee', 1500);
                billing.append(
                  'description',
                  `Pejuang Subuh - Nama ${name} - Phone ${phone} - Blok Rumah ${blok} - Jenis Kelamin ${gender} - Ukuran Baju ${size} - Umur ${age}`
                );
                billing.append('total_price', nominal + 440 + 4000 + 1500);
                // billing.append('total_price', 11);
                setLoading(true);
                axios
                  .post('https://digitalcity.id/api/publicorder2/', billing)
                  .then((res) => {
                    // window.open(res.data.payment_url);
                    setPayment(res.data.payment_url);
                    openModal2();
                  })
                  .catch((err) => {
                    if (err.response.data?.message) {
                      setErrorRegister(err.response.data.message);
                      setTimeout(() => {
                        setErrorRegister();
                      }, 8000);
                    }
                  })
                  .finally(() => {
                    setLoading(false);
                  });
                closeModal();
              }}
            >
              Lanjutkan
            </div>
          </ButtonPrimary>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen2}
        onRequestClose={closeModal2}
        style={customStyles}
        ariaHideApp={false}
      >
        <div className='w-full h-96' />
        <div className='z-50'>
          <div className='flex items-center justify-end'>
            <div onClick={closeModal2} className='cursor-pointer'>
              X
            </div>
          </div>
          <div>
            <iframe
              src={payment}
              title='W3Schools Free Online Web Tutorials'
              height='1000'
              width='100%'
            ></iframe>
          </div>
        </div>
      </Modal>

      <div className='w-full h-20'></div>
      <section className='max-w-6xl mx-auto w-full block lg:flex justify-between lg:space-x-4'>
        <div className='lg:flex mb-6 w-full'>
          <div className='w-full lg:ml-5 mt-5 mb-20 lg:mt-0'>
            <div className='bg-white shadow-lg px-5 rounded-xl py-5'>
              <h2 className='font-bold text-center'>Pejuang Subuh</h2>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='space-y-6 mb-6'>
                  <FormInputField
                    control={control}
                    name='name'
                    type='name'
                    errors={errors}
                    title='Nama Lengkap'
                    placeholder='Contoh: John Doe'
                  />
                  <FormInputField
                    control={control}
                    name='phone'
                    type='number'
                    errors={errors}
                    title='Nomor Telepon'
                    placeholder='85157711065'
                  />
                  <FormInputField
                    control={control}
                    type='text'
                    name='blok'
                    errors={errors}
                    title='Blok Rumah'
                    placeholder='Contoh: blok rumah AG-01'
                  />
                  <div className='flex flex-col'>
                    <div className='text-gray-400'>Jenis Kelamin</div>
                    <div className='flex space-x-4'>
                      <div className='flex space-x-2 items-center'>
                        <div className='flex flex-col'>
                          <div className='flex flex-row items-center relative space-x-3'>
                            <input
                              name='tnc'
                              id='female'
                              type='radio'
                              defaultChecked={
                                gender === 'PEREMPUAN' ? true : false
                              }
                              onChange={(e) => {
                                console.log(e);
                                if (e.target.checked) {
                                  setGender('PEREMPUAN');
                                } else {
                                  setGender('LAKI~LAKI');
                                }
                              }}
                            />
                            <label htmlFor='female' className='text-black'>
                              Perempuan
                            </label>
                          </div>
                        </div>
                        <div className='flex flex-col'>
                          <div className='flex flex-row items-center relative space-x-3'>
                            <input
                              name='tnc'
                              id='male'
                              type='radio'
                              onChange={(e) => {
                                console.log(e);
                                if (e.target.checked) {
                                  setGender('LAKI~LAKI');
                                } else {
                                  setGender('PEREMPUAN');
                                }
                              }}
                            />
                            <label htmlFor='male' className='text-black'>
                              Laki-laki
                            </label>
                          </div>
                        </div>
                      </div>
                      {gender === '' && (
                        <p className='text-red-500'>
                          {'Silakan pilih jenis kelamin Anda'}
                        </p>
                      )}
                    </div>
                    <div className='flex flex-col my-3'>
                      <div className='text-gray-400'>Umur</div>
                      <div className='flex space-x-4'>
                        <div className='flex flex-col'>
                          <div className='flex flex-row items-center relative space-x-3'>
                            <input
                              name='age'
                              id='TK~SD'
                              type='radio'
                              checked={age === 'TK~SD' ? true : false}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setAge('TK~SD');
                                  setNominal(50000);
                                }
                              }}
                            />
                            <label htmlFor='TK~SD' className='text-black'>
                              TK - SD
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='flex space-x-4'>
                        <div className='flex flex-col'>
                          <div className='flex flex-row items-center relative space-x-3'>
                            <input
                              name='age'
                              id='SMP-SMA'
                              type='radio'
                              checked={age === 'SMP~SMA' ? true : false}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setAge('SMP~SMA');
                                  setNominal(50000);
                                }
                              }}
                            />
                            <label htmlFor='SMP-SMA' className='text-black'>
                              SMP - SMA
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='flex space-x-4'>
                        <div className='flex flex-col'>
                          <div className='flex flex-row items-center relative space-x-3'>
                            <input
                              name='age'
                              id='dewasa'
                              type='radio'
                              checked={age === 'Dewasa' ? true : false}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setAge('Dewasa');
                                  setNominal(100000);
                                }
                              }}
                            />
                            <label htmlFor='dewasa' className='text-black'>
                              DEWASA
                            </label>
                          </div>
                        </div>
                      </div>
                      {age === '' && (
                        <p className='text-red-500'>
                          {'Silakan pilih jenis umur Anda'}
                        </p>
                      )}
                    </div>
                    {/* Gambar Ukuran Baju */}
                    <div className='flex w-1/2 pr-4 lg:pr-0 lg:w-1/4 space-x-4'>
                      {
                        age === 'TK~SD' ?
                        <img
                          className=''
                          src={require('../../assets/pejuang-subuh/ukuran-anak.jpg')}
                        /> :
                      <img
                        className=''
                        src={require('../../assets/pejuang-subuh/ukuran-dewasa.jpg')}
                      />
                      }
                    </div>
                    <div className='flex flex-col my-5'>
                      <div className='text-gray-400'>Ukuran Baju</div>
                      <Select
                        className=''
                        name='size'
                        options={[
                          {
                            value: 'XS',
                            label: 'XS (Extra Small)',
                          },
                          {
                            value: 'S',
                            label: 'S (Small)',
                          },
                          {
                            value: 'M',
                            label: 'M (Medium)',
                          },
                          {
                            value: 'L',
                            label: 'L (Large)',
                          },
                          {
                            value: 'XL',
                            label: 'XL (Extra Large)',
                          },
                          {
                            value: 'XXL',
                            label: 'XXL (Double Extra Large)',
                          },
                        ]}
                        onChange={(e) => {
                          setSize(e.value);
                        }}
                        placeholder='Silahkan pilih ukuran baju'
                      />
                      {size === '' && (
                        <p className='text-red-500'>
                          {'Silakan pilih size baju Anda'}
                        </p>
                      )}
                    </div>
                    <div className='flex flex-col'>
                      <label htmlFor='nominal' className='text-gray-400'>
                        Nominal
                      </label>
                      <input
                        value={nominal}
                        disabled
                        className='text-xl py-2 px-2 bg-gray-100'
                      />
                    </div>
                  </div>
                </div>
                {errorRegister ? (
                  <ErrorMessage>{errorRegister}</ErrorMessage>
                ) : null}
                <div>
                  <ButtonPrimary
                    loading={loading}
                    disabled={confirm}
                    type='submit'
                    className='w-full bg-bluesaifillah-normal text-center py-2 mt-4'
                  >
                    {confirm ? 'TERKIRIM' : 'DAFTAR'}
                  </ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PejuangSubuh;

export const USER_DATA_LOCALSTORAGE = 'USER_DATA';
export const USER_TOKEN_LOCALSTORAGE = 'USER_TOKEN';

export const setUserData = (data) => {
  localStorage.setItem(USER_DATA_LOCALSTORAGE, JSON.stringify(data));
};

export const getUserData = () => {
  return JSON.parse(localStorage.getItem(USER_DATA_LOCALSTORAGE));
};

export const setUserToken = (data) => {
  localStorage.setItem(USER_TOKEN_LOCALSTORAGE, JSON.stringify(data));
};

export const getUserToken = () => {
  return JSON.parse(localStorage.getItem(USER_TOKEN_LOCALSTORAGE));
};
import React from "react";

const ImamCilik = () => {
  return (
    <>
      <div className="mt-11 p-6 lg:mx-20">
        <h2 className="text-4xl font-extrabold dark:text-white">
          Program Imam & Muadzin Cilik Masjid Saifillah
        </h2>
        <div className="flex justify-center mt-10 mb-10">
          <div className=" border-black border-solid">
            <img
              src={require("../../assets/ramadhan/imamcilik.png")}
              alt="Imam Cilik Saifillah"
              className="object-cover h-96"
            />
          </div>
        </div>
        <p className="flex justify-center mb-10 my-4 text-lg">
          Silahkan mengisi data berikut untuk mendaftarkan putra/putri Anda menjadi Imam Cilik.
        </p>
        <div className="flex justify-center mt-10 mb-10">
          <iframe title="registrasi imamcilik" src="https://docs.google.com/forms/d/e/1FAIpQLSfObfe_7MKcygoiO8-6d3WolrTLRorKGwO4gTv418dwA2vaIg/viewform?embedded=true" width="800" height="995" frameborder="0" marginheight="0" marginwidth="0">Memuat…</iframe>
        </div>
        
      </div>
    </>
  );
};

export default ImamCilik;

import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
import FormInputField from '../../components/form/FormInputField';
import ErrorMessage from '../../components/ErrorMessage';
import ButtonPrimary from '../../components/ButtonPrimary';
import Modal from 'react-modal';
import Checkbox from './components/Checkbox';

// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//   },
// };

const Kegiatan = () => {
  const [errorRegister, setErrorRegister] = useState();
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [isDisabled, setIsDisabled] =  useState(true)

  const [confirm, setConfirm] = useState(false);

  const [checkboxValues, setCheckboxValues] = useState({
    "Tarhib Keliling": false,
    "Camping Mabit": false,
    "Bengkel Iman": false,
    "Lomba Anak": false,
    "Ifthar Bersama": false,
    "Booklet Petualangan": false,
  });

  const handleCheckboxChange = (name) => {
    setCheckboxValues({
      ...checkboxValues,
      [name]: !checkboxValues[name],
    });
  };


  const [checkboxValues1, setCheckboxValues1] = useState({
    "Muadzin Cilik": false,
    "Imam Cilik": false,
    "Dai Daiyah Cilik": false,
  });

  const handleCheckboxChange1 = (name) => {
    setCheckboxValues1({
      ...checkboxValues1,
      [name]: !checkboxValues1[name],
    });
  };

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [blok, setBlok] = useState('');
  const [parent, setParent] = useState('');
  const [payment, setPayment] = useState('');
  const [motherName, setMotherName] = useState('');
  const [fatherName, setFatherName] = useState('');
  const [fatherPhone, setFatherPhone] = useState('');
  const [motherPhone, setMotherPhone] = useState('');
  const [tpa, setTpa] = useState('');
  const [kelas, setKelas] = useState('');
  const [nominal, setNominal] = useState(125000);
  const [kegiatan, setKegiatan] = useState('');

  const [modalIsOpen, setIsOpen] = React.useState(false);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      overflowY: "auto"
    },
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [modalIsOpen2, setIsOpen2] = React.useState(false);

  const openModal2 = () => {
    setIsOpen2(true);
  };

  const closeModal2 = () => {
    setIsOpen2(false);
  };

  useEffect(() => {
    if (kegiatan === 'TPA') {
      setNominal(100000);
    } else {
      setNominal(125000);
    }
  }, [kegiatan]);

  const schema = yup
    .object({
      name: yup.string().required('Nama lengkap harus diisi'),
      blok: yup.string().required('Blok Rumah lengkap harus diisi'),
      fatherName: yup.string().required('Nama Ayah harus diisi'),
      motherName: yup.string().required('Nama Ibu harus diisi'),
      fatherPhone: yup
        .number('nomor telepon harus berupa angka')
        .typeError('Nomor telepon harus berupa angka')
        .required('Nomor telepon harus diisi'),
      motherPhone: yup
        .number('nomor telepon harus berupa angka')
        .typeError('Nomor telepon harus berupa angka')
        .required('Nomor telepon harus diisi'),
      // nominal: yup
      //   .number('nomor telepon harus berupa angka')
      //   .typeError('Nominal harus berupa angka')
      //   .required('Nominal harus diisi'),
    })
    .required();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      blok: '',
      parent: '',
      fatherPhone: '',
      motherPhone: '',
      kelas: '',
      motherName: '',
      fatherName: '',
      // nominal: nominal,
    },
  });
  console.log(checkboxValues)
  const onSubmit = (e) => {

    setName(e.name);
    setFatherName(e.fatherName);
    setMotherName(e.motherName);
    setFatherPhone(e.fatherPhone);
    setMotherPhone(e.motherPhone);
    setKelas(e.kelas);
    setPhone(e.phone);
    setBlok(e.blok);
    setParent(e.parent);
    setTpa(e.tpa);
    if (confirm) {
      const billing = new FormData();
      billing.append('billingId', 562);
      billing.append('status', 'Belum Lunas');
      billing.append('payment_method', 'Xendit');
      billing.append('transaction_fee', 4000);
      billing.append('vat_fee', 440);
      billing.append('platform_fee', 1500);
      billing.append(
        'description',
        `Kegiatan ${kegiatan} 
        - Nama ${e.name}
        - Phone (${ e.fatherPhone } / ${ e.motherPhone })
        - Jenis Kelamin ${gender}
        - Umur (${age})
        - Kelas ${kelas}
        - Orangtua (${ e.fatherName } / ${ e.motherName })
        - Blok Rumah ${e.blok}
        - Petualangan ${Object.entries(checkboxValues).map(([key, value]) => (value && key) ? key : '')}
        - Lomba ${Object.entries(checkboxValues1).map(([key, value]) => (value && key) ? key : '')}`
      );
      billing.append('total_price', nominal  + 440 + 4000 + 1500);
      setLoading(true);
      axios
        .post('https://digitalcity.id/api/publicorder2/', billing)
        .then((res) => {
          window.open(res.data.payment_url);
        })
        .catch((err) => {
          if (err.response.data?.message) {
            setErrorRegister(err.response.data.message);
            setTimeout(() => {
              setErrorRegister();
            }, 8000);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      openModal();
    }
  };
  return (
    <div className='bg-gray-50'>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
      >
        <div>
          <div className='flex items-center justify-end'>
            <div onClick={closeModal} className='cursor-pointer'>
              X
            </div>
          </div>
        </div>
        <div className='mb-6'>
          <h2 className='font-bold text-xl text-center text-bluesaifillah-normal mb-6'>
            KONFIRMASI
          </h2>
          <h2 className='font-bold text-center mb-3'>
            Mohon mengecek kembali nominal Kegiatan Anda
          </h2>
          <div>
            <div className='flex justify-between'>
              <div>Biaya Pendaftaran</div>
              <div>
                {' '}
                <NumericFormat
                  value={nominal}
                  displayType={'text'}
                  thousandSeparator=','
                  prefix={'Rp. '}
                />
              </div>
            </div>
            <div className='flex justify-between'>
              <div>Biaya Transaksi</div>
              <div>
                {' '}
                <NumericFormat
                  value={5940}
                  displayType={'text'}
                  thousandSeparator=','
                  prefix={'Rp. '}
                />
              </div>
            </div>
            <div className='flex justify-between'>
              <div>Total</div>
              <div>
                {' '}
                <NumericFormat
                  value={parseInt(nominal) + 5940}
                  displayType={'text'}
                  thousandSeparator=','
                  prefix={'Rp. '}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='flex space-x-2'>
          <ButtonPrimary
            // loading={loading}
            type='submit'
            className='w-full bg-white text-gray-800 border-spacing-1 text-center py-2 mt-4'
          >
            <div
              className=' text-gray-500'
              onClick={() => {
                setConfirm(false);
                closeModal();
              }}
            >
              Batalkan
            </div>
          </ButtonPrimary>
          <ButtonPrimary
            loading={loading}
            type='submit'
            className='w-full bg-bluesaifillah text-center py-2 mt-4'
          >
            <div
              onClick={() => {
                setConfirm(true);
                // closeModal();
                const billing = new FormData();
                billing.append('billingId', 562);
                billing.append('status', 'Belum Lunas');
                billing.append('payment_method', 'Xendit');
                billing.append('transaction_fee', 4000);
                billing.append('vat_fee', 440);
                billing.append('platform_fee', 1500);
                billing.append(
                  'description',
                  `Kegiatan ${kegiatan}  - Nama ${name} - Phone ${fatherPhone} / ${motherPhone} - Jenis Kelamin ${gender} - Umur (${age}) - Kelas ${kelas} - Orangtua ${fatherName} / ${motherName} - Blok Rumah ${blok}
                  - Petualangan ${Object.entries(checkboxValues).map(([key, value]) => (value && key) ? key : '')}
                  - Lomba ${Object.entries(checkboxValues1).map(([key, value]) => (value && key) ? key : '')}`
                );
                billing.append('total_price', nominal + 440 + 4000 + 1500);
                setLoading(true);
                axios
                  .post('https://digitalcity.id/api/publicorder2/', billing)
                  .then((res) => {
                    window.open(res.data.payment_url);
                    // setPayment(res.data.payment_url);
                    // openModal2();
                  })
                  .catch((err) => {
                    if (err.response.data?.message) {
                      setErrorRegister(err.response.data.message);
                      setTimeout(() => {
                        setErrorRegister();
                      }, 8000);
                    }
                  })
                  .finally(() => {
                    setLoading(false);
                  });
                closeModal();
              }}
            >
              Lanjutkan
            </div>
          </ButtonPrimary>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen2}
        onRequestClose={closeModal2}
        style={customStyles}
        ariaHideApp={false}
      >
        <div>
        <div className='w-96 h-4' />
        <div className='z-50'>
          <div className='flex items-center justify-end'>
            <div onClick={closeModal2} className='cursor-pointer'>
              X
            </div>
          </div>
          <div>
            <iframe
              src={payment}
              title='Payment'
              height='500'
              width='100%'
            ></iframe>
          </div>
        </div>

        </div>
      </Modal>
      {/* <div className="w-full h-20" /> */}
      <section id='header' className='max-w-6xl mx-auto'>
        <div className='mt-11 p-6 lg:mx-20'>
          <h2 className='text-4xl font-extrabold dark:text-white'>
            Pendaftaran Ramadhan Istimewa Anak Sevilla
          </h2>
          <div className='flex justify-center mt-10'>
            <img
              src={require('../../assets/poster2.png')}
              alt='Poster'
              className='object-cover'
            />
          </div>
        </div>
      </section>
      <section className='max-w-6xl mx-auto'>
        <div className='lg:flex mb-6'>
          <div className='w-full lg:ml-5 mt-5 mb-20 lg:mt-0'>
            <div className='bg-white shadow-lg px-5 rounded-xl py-5 '>
              <h2 className='font-bold text-center'>
                Pendaftaran Ramadhan Istimewa Anak Sevilla
              </h2>
              <div className='text-center'>
                Info lebih lanjut silahkan menghubungi panitia
                {/* <a href='http://wa.me/628164823829' className='text-blue-500'>
                  08164823829
                </a> */}
              </div>
              {/* <form
                onSubmit={handleSubmit(onSubmit)}
                // className="relative row-span-2 mt-12 sm:mt-20 bg-white z-20 shadow-lg w-full border border-turquoise-normal p-7 rounded-lg"
              >
                <div className='space-y-6 mb-6'>
                <FormInputField
                    control={control}
                    name='name'
                    errors={errors}
                    placeholder='Nama Anak'
                    title='Nama Anak'
                  />
                  <FormInputField
                    control={control}
                    name='fatherName'
                    placeholder='Nama Ayah'
                    errors={errors}
                    title='Nama Orangtua Ayah'
                  />
                  <FormInputField
                    control={control}
                    name='motherName'
                    errors={errors}
                    placeholder='Nama Ibu'
                    title='Nama Orangtua Ibu'
                  />
                  <div className='flex flex-col'>
                    <div className='text-gray-400'>Usia Anak (Tahun)</div>
                    <Select
                      name='age'
                      options={[
                        {
                          value: '5',
                          label: '5',
                        },
                        {
                          value: '6',
                          label: '6',
                        },
                        {
                          value: '7',
                          label: '7',
                        },
                        {
                          value: '8',
                          label: '8',
                        },
                        {
                          value: '9',
                          label: '9',
                        },
                        {
                          value: '10',
                          label: '10',
                        },
                        {
                          value: '11',
                          label: '11',
                        },
                        {
                          value: '12',
                          label: '12',
                        },
                      ]}
                      onChange={(e) => {
                        setAge(e.value);
                      }}
                      placeholder='Pilih Usia Anak'
                    />
                  </div>
                  <FormInputField
                    control={control}
                    name='blok'
                    errors={errors}
                    placeholder='Ruko Golden Madrid 2 blok F-19, BSD City Tangerang, Banten, Indonesia'
                    title='Alamat Rumah Lengkap'
                  />
                  <FormInputField
                    control={control}
                    name='kelas'
                    errors={errors}
                    title='Kelas'
                  />
                  <div className='flex flex-col'>
                    <div className='text-gray-400'>Jenis Kelamin Anak</div>
                    <Select
                      name='gender'
                      options={[
                        {
                          value: 'Laki Laki',
                          label: 'Laki Laki',
                        },
                        {
                          value: 'Perempuan',
                          label: 'Perempuan',
                        },
                      ]}
                      onChange={(e) => {
                        setGender(e.value);
                      }}
                      placeholder='Silahkan pilih jenis kelamin'
                    />
                  </div>
                  <FormInputField
                    control={control}
                    name='fatherPhone'
                    errors={errors}
                    type='number'
                    placeholder='62xxxxxxxxxx'
                    title='Nomor Telepon Ayah'
                  />
                  <FormInputField
                    control={control}
                    name='motherPhone'
                    errors={errors}
                    type='number'
                    placeholder='62xxxxxxxxxx'
                    title='Nomor Telepon Ibu'
                  />
                  <div className='flex flex-col'>
                    <div className='text-gray-400'>TPA</div>
                    <Select
                      name='kegiatan'
                      options={[
                        {
                          value: 100000,
                          label: 'TPA',
                          kegiatan: 'TPA',
                        },
                        {
                          value: 125000,
                          label: 'NonTPA',
                          kegiatan: 'NonTPA',
                        },
                      ]}
                      onChange={(e) => {
                        setNominal(e.value);
                        setKegiatan(e.kegiatan);
                        
                      }}
                      placeholder='TPA'
                    />
                  </div>

                  <div>
                  <div className='text-gray-400'>Keikutsertaan Petualangan</div>
                  <div className="container mx-auto mt-5">
                    <Checkbox
                      label="🏡 Tarhib Keliling (Sabtu,2 Maret 2024 13.00-18.00)"
                      checked={checkboxValues.option1}
                      onChange={() => handleCheckboxChange('Tarhib Keliling')}
                    />
                    <Checkbox
                      label="🏕Camping Mabit (Jum'at-Sabtu,8-9 Maret 2024 16.00-10.00)"
                      checked={checkboxValues.option2}
                      onChange={() => handleCheckboxChange('Camping Mabit')}
                    />
                    <Checkbox
                      label="🕋 Bengkel Iman (13,14,15,20,21,22 Maret 2024 16.00-17.45)"
                      checked={checkboxValues.option3}
                      onChange={() => handleCheckboxChange('Bengkel Iman')}
                    />
                    <Checkbox
                      label="🏆 Lomba Anak (Sabtu, 23 Maret 2024 16.00-18.00)"
                      checked={checkboxValues.option3}
                      onChange={() => handleCheckboxChange('Lomba Anak')}
                    />
                    <Checkbox
                      label="🍛 Ifthar bersama (Minggu, 24 Maret 2024 15.00-18.00)"
                      checked={checkboxValues.option3}
                      onChange={() => handleCheckboxChange('Ifthar Bersama')}
                    />
                    <Checkbox
                      label="🎫 Booklet Petualangan (agenda 30 hari ramadhan)"
                      checked={checkboxValues.option3}
                      onChange={() => handleCheckboxChange('Booklet Petualangan')}
                    />

                    <div className="mt-4">
                      <h2>Selected Options:</h2>
                      <ul>
                        {Object.entries(checkboxValues).map(([key, value]) => (
                          value && <li key={key}>{key}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  </div>

                    <div>
                    <div className='text-gray-400'>Keikutsertaan Lomba</div>
                  <div className="container mx-auto mt-5">
                    <Checkbox
                      label="Muadzin Cilik"
                      checked={checkboxValues.option1}
                      onChange={() => handleCheckboxChange1('Muadzin Cilik')}
                    />
                    <Checkbox
                      label="Imam Cilik"
                      checked={checkboxValues.option2}
                      onChange={() => handleCheckboxChange1('Imam Cilik')}
                    />
                    <Checkbox
                      label="Dai Daiyah Cilik"
                      checked={checkboxValues.option3}
                      onChange={() => handleCheckboxChange1('Dai Daiyah Cilik')}
                    />

                    <div className="mt-4">
                      <h2>Selected Options:</h2>
                      <ul>
                        {Object.entries(checkboxValues).map(([key, value]) => (
                          value && <li key={key}>{key}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                    </div>

                  <div className=''>
                    <div className='text-gray-400'>
                      Bersedia mengikuti kegiatan Ramadhan Anak 1445 H
                    </div>
                    <div>
                      <div className='flex flex-col'>
                        <div className='flex flex-row relative space-x-3'>
                          <input
                            className='decoration mt-1'
                            name='isDisabled'
                            id='isDisabled'
                            type='checkbox'
                            onChange={(e) => {
                              if (e.target.checked) {
                                setIsDisabled(false);
                              } else {
                                setIsDisabled(true);
                              }
                            }}
                          />
                          <label htmlFor='isDisabled' className='text-black'>
                            Bersedia
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <FormInputField
                    control={control}
                    errors={errors}
                    name='nominal'
                    title='Nominal'
                    placeholder={nominal}
                    disabled
                  />
                </div>
                {errorRegister ? (
                  <ErrorMessage>{errorRegister}</ErrorMessage>
                ) : null}
                <ButtonPrimary
                  loading={loading}
                  type='submit'
                  disabled={isDisabled}
                  className='w-full bg-[#c49d00] text-center py-2 mt-4'
                >
                  {confirm ? 'Bayar' : 'Daftar SEKARANG'}
                </ButtonPrimary>
                {confirm && (
                  <p className=' mt-6'>
                    Terima kasih telah melakukan proses pembayaran. Untuk
                    mengetahui lebih detail tentang kegiatan program Ramadhan
                    bisa menuju ke{' Group WA'}
                    <a
                      href='https://chat.whatsapp.com/Il0FnlINihoIpl33nD33DD'
                      className='font-medium text-blue-600 dark:text-blue-500 hover:underline'
                    >
                      LINK BERIKUT
                    </a>
                  </p>
                )}
              </form> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Kegiatan;

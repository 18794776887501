export const BASEURL = 'https://digitalcity.id'
// export const BASEURL = "http://127.0.0.1:8000";

export const AUTH_URL_LOGIN = `${BASEURL}/auth/token/login/`
export const AUTH_URL_USERME = `${BASEURL}/auth/users/me/`


export const POST_NEWS = `${BASEURL}/api/news/`
export const EDIT_NEWS = `${BASEURL}/api/news/`
// export const BASEURL = 'https://educourse.id';

// // login
// export const URL_LOGIN = `${BASEURL}/api-agency/login/ `;

// // register
// export const URL_REGISTER_AGENCY = `${BASEURL}/api-agency/registrationagency/ `;
// export const URL_REGISTER_RESELLER = `${BASEURL}/api-agency/registrationreseller/ `;

// // profile
// export const URL_AGENCY_PROFILE = `${BASEURL}/api-agency/agencyprofile/`;
// export const URL_RESELLER_PROFILE = `${BASEURL}/api-agency/resellerprofile/`;

// // blast email
// export const URL_BLASTEMAIL_RESELLER = `${BASEURL}/api-agency/agencyblastemail/`;

// export const OPTIONS = (token) => {
//   return {
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//       Authorization: 'Token ' + token,
//     },
//   };
// };

// export const OPTIONS_FORM_DATA = (token) => {
//   return {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//       Authorization: 'Token ' + token,
//     },
//   };
// };

// export const OPTIONS_FORM_DATA_WITHOUT_TOKEN = () => {
//   return {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   };
// };

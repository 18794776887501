import React from "react";

const Kajian = () => {
  return (
    <>
      <div className="mt-11 p-6 lg:mx-20">
        <h2 className="text-4xl font-extrabold dark:text-white">
          Kajian Masjid Saifillah
        </h2>
        <div class="flex justify-center mt-10 mb-10">
          <img
            src={require("../../assets/kajian/kajian-maret.png")}
            alt="Masjid"
            className="object-cover"
          />
        </div>
      </div>
    </>
  );
};

export default Kajian;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ButtonPrimary from "../../components/ButtonPrimary";
import FormInputField from "../../components/form/FormInputField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { AUTH_URL_LOGIN, AUTH_URL_USERME } from "../../utils/endPoint";
import { useNavigate } from "react-router-dom";
import { setUserData, setUserToken} from "../../utils/types";
import ErrorMessage from "../../components/ErrorMessage";
import { Link } from "react-router-dom";

const Login = () => {
  const [errorRegister, setErrorRegister] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const schema = yup
    .object({
      email: yup
        .string()
        .email("Gunakan format email yang benar ( nama@mail.com )")
        .required("Email harus diisi"),
      password: yup.string().required("Password harus diisi"),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const getUser = async (token) => {
    try {
      const res = await axios.get(AUTH_URL_USERME, {
        headers: { Authorization: `Token ${token}` },
      })
      const data = await res.data
      setUserData(data)
      navigate('/tambah-berita')
    }
    catch(err){
      console.log(err)
    }
  }

  const onSubmit = (e) => {
    const data = new FormData();
    data.append("email", e.email);
    data.append("password", e.password);
    setLoading(true);
    axios
    // 
      .post(AUTH_URL_LOGIN, data)
      .then((res) => {
        const token = res.data.auth_token
        setUserToken(token)
        getUser(token)
      })
      .catch((err) => {
        if (err.response.data?.non_field_errors[0]) {
          setErrorRegister(err.response.data.non_field_errors[0])
          setTimeout(() => {
            setErrorRegister()
          }, 8000)
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <section id="login">
        <div className="container mx-auto px-6 grid grid-cols-1 sm:grid-cols-2 relative max-w-5xl mb-10">
          <div className="sm:mr-12">
            <div className="relative shadow-[0_0_0_100vmax_#156f98] pt-20 pb-10 clip-path-inset-0-to-100 bg-bluesaifillah-normal z-10">
              <div className="space-y-4 flex flex-[0.4] flex-col items-center text-center sm:items-start sm:text-start">
                <h2 className="text-2xl font-bold text-white">
                  Login Dashboard
                </h2>
                <p className="text-white max-w-3xl">
                  Selamat datang, silahkan login terlebih dahulu untuk mengakses
                  dashboard website.
                </p>
              </div>
            </div>
            <div className="space-y-4 flex flex-col items-center text-center sm:items-start sm:text-start sm:py-10">
              <p className="text-black-700"></p>
            </div>
          </div>
          <div className="mt-12 sm:mt-0">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="relative row-span-2 sm:mt-20 bg-white z-20 shadow-lg w-full border border-turquoise-normal p-7 rounded-lg"
            >
              <div className="space-y-6 mb-6">
                <FormInputField
                  control={control}
                  errors={errors}
                  name="email"
                  title="Email"
                />
                <FormInputField
                  control={control}
                  errors={errors}
                  name="password"
                  title="Password"
                  type="password"
                />
              </div>
              {/* <div>
                <span className="text-sm">
                  Belum Punya Akun?{" "}
                  <Link to="/register/meta">
                    <u className="text-blue-400">Registrasi sekarang</u>
                  </Link>
                </span>
              </div> */}
              {errorRegister ? (
                <ErrorMessage>{errorRegister}</ErrorMessage>
              ) : null}
              <ButtonPrimary
                loading={loading}
                type="submit"
                className="w-full bg-bluesaifillah-normal text-center py-2 mt-4"
              >
                Login
              </ButtonPrimary>
            </form>
          </div>
        </div>
      </section>
    </>
  )
};

export default Login;
